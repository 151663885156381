import { Observable } from "rxjs";

import { ListInterface, RespListInterface, UserInterface } from "@common/ports/interfaces";

import { CityInterface } from "@city/ports/interfaces";
import { InstitutionInterface } from "@institution/ports/interfaces";
import { ClientInterface } from "@clients/ports/interfaces";
import { RolPermissionInterface } from "@roles-permissions/ports/interfaces";
import { CountryInterface } from "@countries/ports/interfaces";
import { DeliveryTypeInterface } from "@delivery-type/ports/interfaces";
import { StateInterface } from "@state/ports/interfaces";
import { SaleInterface } from "@sales/ports/interfaces";
import { ProductServiceInterface } from "@products-services/ports/interfaces";
import { FormPaymentInterface } from "@form-payment/ports/interfaces";

export abstract class ListAbstraction {
  abstract listCity(query: string): Observable<RespListInterface<CityInterface>>;
  abstract listClient(query: string): Observable<RespListInterface<ClientInterface>>;
  abstract listCountry(query: string): Observable<RespListInterface<CountryInterface>>;
  abstract listDeliveryType(query: string): Observable<RespListInterface<DeliveryTypeInterface>>;
  abstract listInstitution(query: string): Observable<RespListInterface<InstitutionInterface>>;
  abstract listPaid(query: string): Observable<RespListInterface<FormPaymentInterface>>;
  abstract listProduct(query: string): Observable<RespListInterface<ProductServiceInterface>>;
  abstract listRol(query: string): Observable<RespListInterface<RolPermissionInterface>>;
  abstract listSale(query: string): Observable<RespListInterface<SaleInterface>>;
  abstract listState(query: string): Observable<RespListInterface<StateInterface>>;
  abstract listTypeDocument(): Observable<RespListInterface<ListInterface>>;
  abstract listUser(query: string): Observable<RespListInterface<UserInterface>>;
}
