import { Injectable } from "@angular/core";
import { ValidatorInterface } from "../../ports/interfaces";

@Injectable({
  providedIn: 'root'
})
export class ValidatorsHelper implements ValidatorInterface {
  expRegAddress =                new RegExp(/^[a-zA-ZÀ-ÿ0-9\s,.#\-\/°]+$/i);
  expRegAlfanumericoHyphenated = new RegExp(/^([a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s\-])*$/);
  expRegAlphabetical =           new RegExp(/(^[a-zA-ZÀ-ÿ\u00f1\u00d1]+([a-zA-ZÀ-ÿ\u00f1\u00d1\s.])*)$/);
  expRegAlphanumeric =           new RegExp(/^([a-zA-ZáéíóúÁÉÍÓÚñÑ0-9])*$/);
  expRegCellphone =              new RegExp(/^[0-9 ()-]{1,15}$/);
  expRegPassword =               new RegExp(/^[A-Za-zÁÉÍÓÚáéíóúÑñ0-9@#$%&*]+$/);
  expRegCodeList =               new RegExp(/^([a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s\+\-])*$/);
  expRegDescription =            new RegExp(/^[a-zA-ZÀ-ÿ\u00f1\u00d10-9#+\-\(\)_\*\/\+\?]+([a-zA-ZÀ-ÿ\u00f1\u00d10-9\s.(),:;"'’#+\-\(\)_\*\/\+\?]*)$/);
  expRegEmail =                  new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/);
  expRegIso1 =                   new RegExp(/^([a-zA-Z\-])*$/);
  expRegNumeric =                new RegExp(/^([0-9])*$/);
  expRegQuestion =               new RegExp(/^[a-zA-ZÀ-ÿ\u00f1\u00d10-9]+([a-zA-ZÀ-ÿ\u00f1\u00d10-9\s.()-]*)$/);
  expRegSearch =                 new RegExp(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\d!@#&*()/_+.:{}]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ\d!@#&*()/_+.:{}]+)*$/);
  expRegTitleList =              new RegExp(/(^[a-zA-Z0-9À-ÿ\u00f1\u00d1\+\-]+([a-zA-Z0-9À-ÿ\u00f1\u00d1\s.\+\-])*)$/);
}
