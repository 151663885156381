import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import {
  DisabledOrEnableFieldsInterface,
  HasId,
  ListInterface,
  ValidatorFormGroupInterface,
  ValidatorInterface,
} from "@common/ports/interfaces";
import { ValidatorsHelper } from "./validator.helper";

@Injectable({
  providedIn: "root"
})
export class ValidatorFormGroupHelper implements ValidatorFormGroupInterface {

  private validate: ValidatorInterface = new ValidatorsHelper();

  getMaxDateOrMinDate(year: number, stateDate: 'Year' | 'Mouth'): Date {
    const date = new Date();
    if (stateDate === 'Year') {
      date.setFullYear(date.getFullYear() + year);
      return date
    } else {
      date.setMonth(date.getMonth() + year);
      return date
    }
  }

  showErrors(dataForm: FormGroup): boolean {
    if (!dataForm.invalid) return false;
    else {
      dataForm.markAllAsTouched();
      return true
    }
  }

  focusFirstInvalidField(dataForm: FormGroup): void {    
    
    Object.keys(dataForm.controls).forEach(field => {
      const control = dataForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  
    const invalidControl = Object.keys(dataForm.controls).find(field => {
      const control = dataForm.get(field);
      return control?.invalid;
    });

    if (invalidControl) {
      const invalidElement = document.querySelector(`[formControlName="${invalidControl}"]`);
      (invalidElement as HTMLElement)?.focus();
    }
  }

  validateFields(dataForm: FormGroup, campo: string): boolean | undefined {
    return dataForm.get(campo)?.invalid && dataForm.get(campo)?.touched;
  }

  setData<T>(dataForm: FormGroup, filed: string, data: T): void {
    dataForm.get(filed)?.setValue(data);
  }

  disabledOrEnableFields(dataForm: FormGroup, fields: DisabledOrEnableFieldsInterface[]): void {
    for (let index = 0; index < fields.length; index++) {
      if (fields[index].disabled) {
        dataForm.get(fields[index].name)?.disable();
        if (fields[index].resetValue) {
          this.resetData(dataForm, fields[index].name)
        }
      } else {
        dataForm.get(fields[index].name)?.enable();
        if (fields[index].resetValue) {
          this.resetData(dataForm, fields[index].name)
        }
      }
    }
  }

  /**
   * Method for clearing the field
   * @param field string
   */
  private resetData(dataForm: FormGroup, field: string): void {
    dataForm.get(field)?.reset();
    dataForm.get(field)?.setValue(null);
  }

  formatIncomingRed(dateIncoming: string | null, gtm: number): string {
    if (dateIncoming) {

      const date = new Date(dateIncoming);
      date.setUTCHours(date.getUTCHours() - gtm);
      const dateString = date.toISOString().split('T');
      const hour = dateString[1].split('.');
      const res = `${dateString[0]} ${(hour[0] === '00:00:00') ? '23:59:59' : hour[0]}.000Z`;

      return res;
    } else return '';
  }

  formatIncomingRedFilters(dateIncomingInit: string | null, dateIncomingEnd: string | null, gtm: number): { resInit: string; resEnd: string; } | null {
    if (dateIncomingInit && dateIncomingEnd) {

      const date1 = new Date(dateIncomingInit);
      date1.setUTCHours(date1.getUTCHours() - gtm);
      const dateStringInit = date1.toISOString().split('T');

      const date2 = new Date(dateIncomingEnd);
      date2.setUTCHours(date2.getUTCHours() - gtm);
      const dateStringEnd = date2.toISOString().split('T');

      const resInit = `${dateStringInit[0]} 00:00:00.000Z`;
      const resEnd = `${dateStringEnd[0]} 23:59:59.000Z`;

      return { resInit, resEnd };
    } else return null;
  }

  formatDateFilters(date: string | null, gtm: number): string | null {
    if (date) {

      const date1 = new Date(date);
      date1.setUTCHours(date1.getUTCHours() - gtm);
      const dateStringInit = date1.toISOString().split('T');
      const resEnd = `${dateStringInit[0]} 23:59:59.000Z`;

      return resEnd;
    } else return null;
  }

  equalFields(campo1: string, campo2: string): (formGroup: AbstractControl) => ValidationErrors | null {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      let pass1: string | null = formGroup.get(campo1)?.value;
      let pass2: string | null = formGroup.get(campo2)?.value;
      if (!pass1 && !pass2) {
        pass1 = null;
        pass2 = null;
      }
      if (pass1 !== pass2) {
        formGroup.get(campo2)?.setErrors({ passwordsNotSame: true })
        return { passwordsNotSame: true }
      }
      formGroup.get(campo2)?.setErrors(null);
      return null
    }
  }

  equalFieldsCalendar(field1: string, field2: string): (formGroup: AbstractControl) => ValidationErrors | null {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const calendar1 = formGroup.get(field1)?.value;
      const calendar2 = formGroup.get(field2)?.value;

      const validTemp: boolean = (calendar2 && !calendar1) ? true : false;

      if (validTemp || calendar1 > calendar2) {
        formGroup.get(field2)?.setErrors({ comparingDates: true })
        return { comparingDates: true }
      }
      formGroup.get(field2)?.setErrors(null)
      return null
    }
  }

  equalFieldsCalendarMonth(campo1: string, campo2: string): (formGroup: AbstractControl<any, any>) => ValidationErrors | null {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const calendar1 = formGroup.get(campo1)?.value as Date;
      const calendar2 = formGroup.get(campo2)?.value as Date;

      if (calendar1 && calendar2) {
        const millisecondsInOneDay = 24 * 60 * 60 * 1000;
        const expectedDifferenceInDays = 31;

        const differenceInDays = Math.round(Math.abs((calendar2.getTime() - calendar1.getTime()) / millisecondsInOneDay));

        if (differenceInDays > expectedDifferenceInDays) {
          formGroup.get(campo2)?.setErrors({ comparingDatesMonth: true });
          return { comparingDatesMonth: true };
        }
      }

      formGroup.get(campo2)?.setErrors(null);
      return null;
    }
  }

  setListSelect<T extends HasId>(listDataRead: T[], getList: T[]): T[] {
    const dataEnd: T[] = []

    for (let index = 0; index < listDataRead.length; index++) {
      const element = listDataRead[index];

      const dataTemp = getList.filter(data => data.id === element.id);

      if (dataTemp.length > 0) {
        const data: T = {
          ...dataTemp[0],
          id: dataTemp[0].id,
        }

        dataEnd.push(data);
      }
    }

    return dataEnd;
  }

  getMinDateOrMaxDate(year: number, stateDate: 'Year' | 'Mouth'): Date {
    const date = new Date();
    if (stateDate === 'Year') {
      date.setFullYear(date.getFullYear() - year);
      return date
    } else {
      date.setMonth(date.getMonth() - year);
      return date
    }
  }

  selectTypes(type: ListInterface, formGroup: FormGroup, field: string) {
    formGroup.get(field)?.clearValidators();
    if (type && type.code) formGroup.get(field)?.enable();
    switch (type.code) {
      case 'CC':
        formGroup.get(field)?.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(15), Validators.pattern(this.validate.expRegNumeric)]);
        break;
      case 'NIT':
        formGroup.get(field)?.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(15), Validators.pattern(this.validate.expRegNumeric)]);
        break;
      case 'CE':
        formGroup.get(field)?.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(15), Validators.pattern(this.validate.expRegAlphanumeric)]);
        break;
      case 'PP':
        formGroup.get(field)?.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(15), Validators.pattern(this.validate.expRegAlphanumeric)]);
        break;
    }
    formGroup.get(field)?.updateValueAndValidity();
  }

  setValidators(formGroup: FormGroup, field: string, validator: ValidatorFn | ValidatorFn[] | null): void {
    formGroup.get(field)?.clearValidators();
    formGroup.get(field)?.setValidators(validator);
    formGroup.get(field)?.updateValueAndValidity();
  }

  cellphoneString(cellphone: string): string {
    cellphone = cellphone.replace(/\(/, '');
    cellphone = cellphone.replace(/\)/, '');
    cellphone = cellphone.replace(/-/, '');
    cellphone = cellphone.replace(/ /, '');
    return cellphone;
  }

  stringNumber(stringNumber: string): number {
    stringNumber = stringNumber.replace(/,/, '.');
    stringNumber = stringNumber.replace(/%/, '');
    return parseFloat(stringNumber);
  }

  formData(data: object): FormData {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            formData.append(`${key}[${index}]`, item);
          });
        } else {
          formData.append(key, value);
        }
      }
    };

    return formData;
  }
}
