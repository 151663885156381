import { Injectable } from "@angular/core";


@Injectable({
    providedIn: "root"
})
export class DataUserHelper {

    getTypeUserAndAdmin(): boolean {
        const type = localStorage.getItem('type');
        return (type && (type === 'User' || type === 'Administrator')) ? true : false;
    }

    getTypeAdmin(): boolean {
        const type = localStorage.getItem('type');
        return (type && type === 'Administrator') ? true : false;
    }
    
    getIdUser(): string {
        const idUser = localStorage.getItem('idUser');
        return (idUser) ? idUser : '';
    }
}