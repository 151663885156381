import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { TableLazyLoadEvent } from "primeng/table";

import {
  HasId,
  ListHelperInterface,
} from "@common/ports/interfaces";

@Injectable({
  providedIn: "root"
})
export class ListHelper implements ListHelperInterface {

  multiSelectString<T extends HasId>(multiSelect: T[]): string[] {
    const dataString: string[] = [];
    for (const data of multiSelect) dataString.push(data.id!);
    return dataString
  }

  processResponse<T extends HasId>(list: T[], data: T): T[] {
    const isDataInclude = list.some(item => item.id === data.id);
    if (data && isDataInclude != true) list.unshift(data);
    return list;
  }

  buildQueryString(page: number, limit: number, valueSearch: string | null, sortingCode: string): string {
    let query = `?page=${page}&limit=${limit}&sorting=asc&sortingCode=${sortingCode}`;
    if (valueSearch) query += `&search=${valueSearch}`;
    return query;
  }

  calculateLimit(isBtnSearchFilter: boolean, currentLimit: number = 10): number {
    if (isBtnSearchFilter) {
      return currentLimit + 10;
    }
    return currentLimit;
  }  

  handleError(error: HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      console.error('HTTP Error:', error.message);
    } else {
      console.error('An error occurred:', error);
    }
  }

  searchListDate(input: string | null): string | null {

    if (input) {
      const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      const match = input.match(dateRegex);

      if (match) {
        const day = match[1];
        const month = match[2];
        const year = match[3];

        const date = new Date(`${year}-${month}-${day}T23:59:59.000Z`);

        return date.toISOString();
      }
    }

    return input;
  }

  getListQuery<T>(queryTable: TableLazyLoadEvent, valueSearch: string | null, filterGlobal: T | null, sortField: string = 'createDate'): string {
    const limit = (queryTable.rows) ? queryTable.rows : 10;
    const page = (queryTable.first) ? (queryTable.first / limit) + 1 : 1;

    let query = `?page=${page}&limit=${limit}`;
    query += `&sorting=${(queryTable.sortField) ? ((queryTable.sortOrder === 1) ? 'asc' : 'desc') : 'desc'}`;
    query += `&sortingCode=${(queryTable.sortField) ? queryTable.sortField : sortField}`;

    if (valueSearch) query += `&search=${valueSearch}`;
  
    query = this.filterString(filterGlobal, query);

    return query;
  }

  filterString<T>(filterGlobal: T | null, query: string): string {
    if (filterGlobal) {
      Object.entries(filterGlobal).forEach(([key, value]) => {
        if (value) query = query + `&${key}=${value}`;
      });
    }
    return query;
  }
}
